<div class="container mx-auto mt-8">
  <div class="flex items-center justify-between mb-4">
    <!-- Use the image as title -->
    <img src="https://cdn.prod.website-files.com/6659947600c0d43827718e80/6659981a9ac62339ee4f7413_logo.jpg"
         alt="Aisel Connect"
         class="h-10" />
    <!-- Logout and Settings -->
    <div class="flex items-center space-x-4">
      <button mat-raised-button
              color="primary"
              (click)="refreshStatus()"
              [disabled]="isRefreshing"
              class="ml-4">
        <mat-icon [ngClass]="{'rotating': isRefreshing}">refresh</mat-icon>
        {{ isRefreshing ? 'Refreshing status...' : 'Refresh Status' }}
      </button>
      <button mat-button (click)="navigateToSettings()">Settings</button>
      <a (click)="logout()" class="text-blue-600 cursor-pointer">Logout</a>
    </div>
  </div>

  <!-- Prompt Comparison Form for Developers -->
  <app-prompt-comparison style="width" *ngIf="showCreatePrompts"></app-prompt-comparison>


  <!-- Add Patient Form -->
  <button *ngIf="false" (click)="toggleForm()" class="form-toggle">
    {{ isFormExpanded ? 'Hide' : 'Show' }} Form
  </button>
  <form #form="ngForm" (ngSubmit)="addPatient(form)" [ngClass]="{'collapsed': !isFormExpanded}" class="mb-4 new_patient_form" *ngIf="!showCreatePrompts">
    <div class="flex items-center space-x-4 text-sm">
      <mat-form-field appearance="fill" class="w-1/5">
        <mat-label>Name</mat-label>
        <input matInput
               [(ngModel)]="newPatient.Name"
               name="name"
               required
               class="py-1" />
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-1/5">
        <mat-label>Phone</mat-label>
        <input matInput
               [(ngModel)]="newPatient.Phone"
               name="phone"
               required
               pattern="^(?:\+45)?\s?\d{8}$"
               class="py-1" />
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-1/5">
        <mat-label>Therapist Name</mat-label>
        <input matInput
               [(ngModel)]="newPatient.DoctorName"
               name="doctorName"
               [placeholder]="defaultTherapistName"
               class="py-1" />
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-1/5">
        <mat-label>Meeting Date</mat-label>
        <input matInput
               [matDatepicker]="picker"
               [min]="minDate"
               [(ngModel)]="newPatient.MeetingDate"
               name="meetingDate"
               class="py-1"
               required
               [matDatepickerFilter]="dateFilter"
               (dateChange)="onDateChange($event)"
               (paste)="handlePaste($event)"
               placeholder="DD/MM/YYYY" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-1/5" style="width:130px">
        <mat-label>Meeting Time</mat-label>
        <input matInput
               type="time"
               [(ngModel)]="meetingTime"
               name="meetingTime"
               (change)="updateMeetingDateTime()"
               class="py-1"
               required
               placeholder="HH:mm"
               step="60" />
      </mat-form-field>

      <button mat-raised-button
              color="primary"
              type="submit"
              class="ml-auto whitespace-nowrap">
        Create Patient
      </button>
    </div>
  </form>

  <!-- Filters -->
  <div class="mb-4 flex items-center space-x-4">
    <mat-form-field appearance="outline" class="w-1/3">
      <!-- Removed label and added placeholder -->
      <input matInput
             placeholder="Filter by name, phone, Aisel ID or status"
             (keyup)="applyFilter()"
             [(ngModel)]="filterValue"
             class="py-1" />
    </mat-form-field>
    <!-- Status Filter Dropdown -->
    <mat-form-field appearance="fill" class="w-1/4" *ngIf="!showCreatePrompts">
      <mat-select placeholder="Filter by Status"
                  [(ngModel)]="selectedStatuses"
                  multiple
                  (selectionChange)="onStatusFilterChange()">
        <mat-select-trigger>
          {{ getStatusLabel() }}
        </mat-select-trigger>
        <div class="px-3 py-2">
          <a (click)="selectAll()"
             class="text-gray-600 underline text-sm cursor-pointer mr-4">Select All</a>
          <a (click)="selectNone()"
             class="text-gray-600 underline text-sm cursor-pointer">Select None</a>
        </div>
        <mat-option *ngFor="let status of statusOptions"
                    [value]="status">
          {{ status }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-checkbox [(ngModel)]="showArchived" (change)="loadPatients()" color="purple" *ngIf="!showCreatePrompts">
      Show Archived
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="showAll" (change)="loadPatients()" color="primary" *ngIf="showCreatePrompts">
      Show All
    </mat-checkbox>
  </div>

  <!-- Patients Table -->
  <table *ngIf="!isReviewer && !showCreatePrompts" mat-table [dataSource]="dataSource" matSort class="w-full">
    <ng-container matColumnDef="Phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone Number</th>
      <td mat-cell *matCellDef="let patient">{{ patient.Phone }}</td>
    </ng-container>

    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Patient Name</th>
      <td mat-cell *matCellDef="let patient">{{ patient.Name }}</td>
    </ng-container>

    <!--
  <ng-container matColumnDef="DateOfCreation">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Creation Date</th>
    <td mat-cell *matCellDef="let patient">
      {{ patient.DateOfCreation | utcToLocal  }}
    </td>
  </ng-container>
    -->

    <ng-container matColumnDef="DoctorName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Therapist Name</th>
      <td mat-cell *matCellDef="let patient">{{ patient.DoctorName }}</td>
    </ng-container>

    <!-- Find the MeetingDate column in the table and replace it with this new version -->
    <ng-container matColumnDef="MeetingDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Meeting Date</th>
      <td mat-cell *matCellDef="let patient">
        <!-- Regular date display -->
        <div *ngIf="!isEditingDate[patient.ID]" class="flex items-center">
          {{ patient.MeetingDate | utcToLocal }}
          <!-- Only show edit button for standard users -->
          <button *ngIf="patient.MeetingDate"
                  mat-icon-button
                  class="ml-2"
                  (click)="startEditingDate(patient)"
                  matTooltip="Edit consultation date">
            <mat-icon class="text-gray-500 text-sm">edit</mat-icon>
          </button>
        </div>

        <!-- Edit mode -->
        <div *ngIf="isEditingDate[patient.ID]" class="flex items-center space-x-2">
          <mat-form-field appearance="fill" class="w-32">
            <input matInput
                   [matDatepicker]="editPicker"
                   [min]="minDate"
                   [(ngModel)]="editDateValue[patient.ID]"
                   [matDatepickerFilter]="dateFilter"
                   placeholder="DD/MM/YYYY">
            <mat-datepicker-toggle matSuffix [for]="editPicker"></mat-datepicker-toggle>
            <mat-datepicker #editPicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-24">
            <input matInput
                   type="time"
                   [(ngModel)]="editTimeValue[patient.ID]"
                   placeholder="HH:mm"
                   step="60">
          </mat-form-field>

          <div class="flex space-x-1">
            <button mat-icon-button
                    color="primary"
                    (click)="updateMeetingDate(patient)"
                    matTooltip="Save changes">
              <mat-icon>check</mat-icon>
            </button>
            <button mat-icon-button
                    color="warn"
                    (click)="cancelEditingDate(patient)"
                    matTooltip="Cancel">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let patient">
        <span [ngClass]="getStatusClass(patient.Status)"
              class="px-2 py-1 rounded text-sm">
          {{ patient.Status }}
        </span>
        <br />
        <span class="text-xs text-gray-500 pad2">
          {{ patient.StatusUpdatedDate | utcToLocal  }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let patient">
        <button matTooltip="Send SMS to patient" mat-icon-button
                (click)="sendInviteSMS(patient)">
          <mat-icon>sms</mat-icon>
        </button>
        <button matTooltip="Delete patient" mat-icon-button
                *ngIf="patient.Status === '1 Created'"
                (click)="deletePatient(patient)">
          <mat-icon>delete</mat-icon>
        </button>
        <button matTooltip="Show Report" mat-icon-button
                *ngIf="patient.Status === '7 Report ready' || patient.Status === '8 Report copied'"
                (click)="viewReport(patient)">
          <mat-icon>visibility</mat-icon>
        </button>
        <button matTooltip="Copy Report" mat-icon-button
                *ngIf="patient.Status === '7 Report ready' || patient.Status === '8 Report copied'"
                (click)="copyReport(patient)">
          <mat-icon>content_copy</mat-icon>
        </button>
        <button matTooltip="{{ patient.ArchivedDate ? 'Unarchive Patient' : 'Archive Patient' }}"
                mat-icon-button
                (click)="archiveTogglePatient(patient)">
          <mat-icon>{{ patient.ArchivedDate ? 'unarchive' : 'archive' }}</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="MoreActions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let patient">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More actions">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="copyAiselId(patient.KeyInTopicX)">
            <mat-icon>content_copy</mat-icon>
            <span>Copy Aisel ID</span>
          </button>
          <button mat-menu-item (click)="sendReminderSMS(patient)">
            <mat-icon>message</mat-icon>
            <span>Send Reminder SMS</span>
          </button>
          <button mat-menu-item (click)="notifyAiselTeam(patient)">
            <mat-icon>notification_important</mat-icon>
            <span>Notify Aisel Team</span>
          </button>
          <button mat-menu-item (click)="deletePatient(patient)">
            <mat-icon>delete</mat-icon>
            <span>Delete Patient</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{'archived-row': row.ArchivedDate}"
        class="hover:bg-gray-50 text-sm"></tr>
  </table>

  <!-- Patients Table for Reviewers -->
  <table mat-table [dataSource]="dataSource" matSort class="w-full" *ngIf="isReviewer && !showCreatePrompts">
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Patient Name</th>
      <td mat-cell *matCellDef="let patient">{{ patient.Name }}</td>
    </ng-container>

    <ng-container matColumnDef="MeetingDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Meeting Date</th>
      <td mat-cell *matCellDef="let patient">
        {{ patient.MeetingDate | utcToLocal }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let patient">
        <span [ngClass]="getStatusClass(patient.Status)"
              class="px-2 py-1 rounded text-sm">
          {{ patient.Status }}
        </span>
        <br />
        <span class="text-xs text-gray-500 pad2">
          {{ patient.StatusUpdatedDate | utcToLocal  }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let patient">
        <button mat-icon-button (click)="selectPatient(patient)" matTooltip="Edit Report">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['Name', 'MeetingDate', 'Status', 'Actions']"></tr>
    <tr mat-row
        *matRowDef="let row; columns: ['Name', 'MeetingDate', 'Status', 'Actions']"
        class="hover:bg-gray-50 text-sm"></tr>
  </table>

  <!-- Developer Table -->
  <table *ngIf="showCreatePrompts" mat-table [dataSource]="dataSource" matSort class="w-full">
    <!-- Star Column -->
    <ng-container matColumnDef="star">
      <th mat-header-cell *matHeaderCellDef class="w-12">Star</th>
      <td mat-cell *matCellDef="let patient" class="w-12">
        <mat-icon [style.color]="isStarred(patient.ID) ? 'gold' : 'gray'"
                  class="cursor-pointer hover:opacity-80 transition-opacity"
                  (click)="handleStarClick($event, patient)">
          {{ isStarred(patient.ID) ? 'star' : 'star_outline' }}
        </mat-icon>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Patient Name</th>
      <td mat-cell *matCellDef="let patient">{{ patient.Name }}</td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let patient">
        <span [ngClass]="getStatusClass(patient.Status)" class="px-2 py-1 rounded text-sm">
          {{ patient.Status }}
        </span>
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let patient">
        <button mat-button
                color="primary"
                matTooltip="Show Left Report"
                (click)="$event.stopPropagation(); handleShowReport(patient, true)">
          <mat-icon>description</mat-icon>
          <span>Left Report</span>
        </button>

        <button mat-button
                color="primary"
                matTooltip="Show Right Report"
                (click)="$event.stopPropagation(); handleShowReport(patient, false)">
          <mat-icon>description</mat-icon>
          <span>Right Report</span>
        </button>

        <button mat-button
                color="primary"
                matTooltip="Compare Reports"
                (click)="$event.stopPropagation(); handleCompare(patient)">
          <mat-icon>compare</mat-icon>
          <span>Compare</span>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="developerColumns"></tr>
    <tr mat-row
        *matRowDef="let row; columns: developerColumns"
        (click)="selectedPatient = row"
        [class.selected]="selectedPatient === row"
        class="hover:bg-gray-50 cursor-pointer"></tr>
  </table>

  <!-- Pagination Controls -->
  <mat-paginator [pageSizeOptions]="[5, 10, 25]"
                 [pageSize]="25"
                 showFirstLastButtons></mat-paginator>
</div>

