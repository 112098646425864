import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class PromptService {
  private apiUrl = location.href.indexOf("localhost") > -1 ? 'http://localhost:7176/api/' : 'https://aiselclinic.azurewebsites.net/api/';

  constructor(private http: HttpClient, private authService: MsalService) { }

  getPrompts(): Observable<any[]> {
    return this.getToken().pipe(
      switchMap((accessToken) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        });
        return this.http.post<any[]>(`${this.apiUrl}GetPrompts` + (location.href.indexOf("localhost") > -1 ? '' : `?code=Y1fXixWl-ex1P15eyBXN3VXcVWERQstrJtdKzAiziC0FAzFuKAHTdg%3D%3D`), {}, { headers }).pipe(
          catchError(this.handleError)
        );
      })
    );
  }

  getPromptVersions(promptName: string): Observable<number[]> {
    return this.getToken().pipe(
      switchMap((accessToken) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        });
        return this.http.post<number[]>(`${this.apiUrl}GetPromptVersions` + (location.href.indexOf("localhost") > -1 ? '' : `?code=NYjSIuH9K9-1JuOJ5PfaDRd7SHvBuWm8BI4Z4dOJ1OUWAzFuTcUZ2Q%3D%3D`), { promptName }, { headers }).pipe(
          catchError(this.handleError)
        );
      })
    );
  }

  getPrompt(promptName: string, version: number | null): Observable<any> {
    return this.getToken().pipe(
      switchMap((accessToken) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        });
        return this.http.post<any>(`${this.apiUrl}GetPrompt` + (location.href.indexOf("localhost") > -1 ? '' : `?code=0SfegGOK-NxM1tZV3s6q2-4wo_jnktbYeKDYWoV4kJ_XAzFu8fH8iQ%3D%3D`), { promptName, version }, { headers }).pipe(
          catchError(this.handleError)
        );
      })
    );
  }

  savePrompt(promptData: any): Observable<any> {
    return this.getToken().pipe(
      switchMap((accessToken) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        });
        return this.http.post<any>(`${this.apiUrl}SavePrompt` + (location.href.indexOf("localhost") > -1 ? '' : `?code=WgbmZlkmxyd10hFeBUd0EWvwnd-g0ApY46RNiVa7P5ZXAzFu9DYFRg%3D%3D`), promptData, { headers }).pipe(
          catchError(this.handleError)
        );
      })
    );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred';
    if (error.error instanceof ErrorEvent) {
      // Client-side or network error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Backend returned an unsuccessful response code
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }

  private getToken(): Observable<string> {
    const account = this.authService.instance.getActiveAccount();
    if (!account) {
      this.authService.loginRedirect();
      return new Observable<string>();
    }
    const tokenRequest = {
      scopes: ['user.read'],
      account: account
    };
    return from(this.authService.acquireTokenSilent(tokenRequest)).pipe(
      switchMap((result) => {
        return new Observable<string>((observer) => {
          observer.next(result.accessToken);
          observer.complete();
        });
      })
    );
  }
}
