import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private msalService: MsalService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const account = this.msalService.instance.getActiveAccount();

    if (!account || !account.idToken) {
      console.error('No active account found or no ID token');
      this.router.navigate(['/login']);
      return false;
    }

    try {
      const decodedToken: any = jwtDecode(account.idToken);
      const email = decodedToken.preferred_username;

      if (!email) {
        console.error('Unable to extract family name or email from token');
        this.msalService.logout();
        return false;
      }

      const isValidUser = email.toLowerCase().endsWith('@aisel.co');

      if (!isValidUser) {
        console.error('Invalid user credentials');
        this.msalService.logout();
        return false;
      }

      return true;
    } catch (error) {
      console.error('Error decoding token:', error);
      this.router.navigate(['/login']);
      return false;
    }
  }
}
