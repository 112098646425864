// src/app/patient.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Patient } from './models/patient.model';
import { Observable, from, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  private apiUrl = location.href.indexOf("localhost") > -1 ? 'http://localhost:7176/api/' : 'https://aiselclinic.azurewebsites.net/api/';
  //private apiUrl = 'http://localhost:7176/api/';

  constructor(private http: HttpClient, private authService: MsalService) { }

  isReviewer(): boolean {
    const account = this.authService.instance.getActiveAccount();
    return !!account && account.username.startsWith('reviewer');
  }

  getCurrentUserRole(): string {
    const account = this.authService.instance.getActiveAccount();
    if (!account) return 'regular';
    if (account.username.startsWith('reviewer')) return 'reviewer';
    if (account.username.startsWith('dev_')) return 'developer';
    return 'regular';
  }

  getPatients(showArchived: boolean, isDevShowAll: boolean = false): Observable<Patient[]> {
    const account = this.authService.instance.getActiveAccount();
    if (!account) {
      this.authService.loginRedirect();
      return new Observable<Patient[]>(); // Return empty observable
    }

    const tokenRequest = {
      scopes: ['user.read'], // Use 'user.read' scope
      account: account
    };

    return from(this.authService.acquireTokenSilent(tokenRequest)).pipe(
      switchMap((result) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${result.accessToken}`,
          'Content-Type': 'application/json'
        });

        const userRole = this.getCurrentUserRole();
        // Send JSON in POST request
        const body = {
          showArchived: showArchived,
          userRole: userRole,
          showAllClinics: isDevShowAll
        };

        return this.http.post<Patient[]>(`${this.apiUrl}GetPatients` + (location.href.indexOf("localhost") > -1 ? '' : `?code=yWKxwinQDb1vzw3AQov3-Rdzz8C1l2Ew2uQ0gl3aeCUKAzFuuVHmuQ%3D%3D`), body, { headers });
      })
    );
  }

  updatePatientReport(patientId: string, reportReviewed: string): Observable<any> {
    return this.getToken().pipe(
      switchMap((accessToken) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        });

        return this.http.post(`${this.apiUrl}UpdatePatientReport` + (location.href.indexOf("localhost") > -1 ? '' : `?code=snY2dM8iTSV_2vPkXp5gbU0RSt_yhjDGwFiJwZNfQGVXAzFuDcBbBg%3D%3D`), {
          patientId: patientId,
          report: reportReviewed,
          newStatus: '7 Report ready'
        }, { headers }).pipe(
          catchError(this.handleError)
        );
      })
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  addPatient(patient: Partial<Patient>): Observable<Patient> {
    return this.getToken().pipe(
      switchMap((accessToken) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        });
        //return this.http.post<Patient>(`${this.apiUrl}AddPatient`, patient, { headers });
        return this.http.post<Patient>(`${this.apiUrl}AddPatient` + (location.href.indexOf("localhost") > -1 ? '' : `?code=g4lA3KnHP0mwQV1vtmuLnf8VlxEWO7sRC_-mQZ9BGBa4AzFuxjkPCQ%3D%3D`), patient, { headers });
      })
    );
  }

  updatePatientStatus(patientId: string, newStatus: string): Observable<void> {
    return this.getToken().pipe(
      switchMap((accessToken) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        });
        return this.http.post<void>(`${this.apiUrl}UpdatePatientStatus` + (location.href.indexOf("localhost") > -1 ? '' : `?code=ll6Dlmnu1o-biVl6Gjzr8FNDYK77PRz4cbtbuW7mDMClAzFuB5GfMw%3D%3D`), { "patientId": patientId, "newStatus": newStatus }, { headers });
      })
    );
  }

  sendSms(patient: Patient, message: string, sender: string): Observable<any> {
    return this.getToken().pipe(
      switchMap((accessToken) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        });
        const body = {
          phoneNumber: patient.Phone,
          message: message,
          sender: sender,
          patientId: patient.ID
        };
        return this.http.post(`${this.apiUrl}SendSms` + (location.href.indexOf("localhost") > -1 ? '' : `?code=uktPQa-oAhKRD0jW0WbHAvjDMRVlVVjewQ2q89Ldj98eAzFu0qu6JA%3D%3D`), body, { headers });
      })
    );
  }

  // src/app/patient.service.ts

  archivePatient(patientId: string, archive: boolean): Observable<void> {
    return this.getToken().pipe(
      switchMap((accessToken) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        });

        // Send patientId and archive flag as JSON in the body of the request
        const body = { patientId, archive };

        return this.http.post<void>(`${this.apiUrl}ArchivePatient` + (location.href.indexOf("localhost") > -1 ? '' : `?code=JP9C4-4dsL_uu5vMl-vmB1mZ8piOX74EaSb9ZlqhH4R-AzFuebj7kg%3D%3D`), body, { headers });
      })
    );
  }



  deletePatient(id: string): Observable<void> {
    return this.getToken().pipe(
      switchMap((accessToken) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${accessToken}`
        });
        const url = `${this.apiUrl}DeletePatient` + (location.href.indexOf("localhost") > -1 ? '' : `?code=_tqWEAZsAPOe2OTcZNjy-jqLfjrw9hbzqkuPY9HKpB91AzFu4rpmLQ%3D%3D`); // Adjust the endpoint URL
        return this.http.post<void>(url, { id:id }, { headers });
        //return this.http.post<void>(`${this.apiUrl}ArchivePatient` + (location.href.indexOf("localhost") > -1 ? '' : `?code=YOUR_FUNCTION_CODE`, { patientId }, { headers });
      })
    );
  }

  refreshStatus(): Observable<void> {
    return this.getToken().pipe(
      switchMap((accessToken) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        });
        return this.http.post<void>(`${this.apiUrl}RefreshStatus` + (location.href.indexOf("localhost") > -1 ? '' : `?code=ALmQrDb7wO84Fy6tJFKfOo_UDp9MjGjr4yMMWbZdRptUAzFuOMD8bQ%3D%3D`), {}, { headers });
      })
    );
  }

  toggleStar(patient: Patient): Observable<any> {
    if (patient.ID.startsWith('_')) {
      // If starred, use existing delete functionality
      return this.deletePatient(patient.ID);
    } else {
      // If not starred, create a starred copy
      const starredPatient: Partial<Patient> = {
        ID: `_${patient.ID}`,
        Name: patient.Name,
        Phone: patient.Phone,
        KeyInTopicX: patient.KeyInTopicX,
        DoctorName: patient.DoctorName,
        MeetingDate: patient.MeetingDate,
        Status: patient.Status,
        Report: patient.Report,
        ReportReviewed: patient.ReportReviewed,
        Blob: patient.Blob,
        Clinic: 'DevPrompts'
      };

      return this.addPatient(starredPatient);
    }
  }

  private getToken(): Observable<string> {
    const account = this.authService.instance.getActiveAccount();
    if (!account) {
      this.authService.loginRedirect();
      return new Observable<string>();
    }

    const tokenRequest = {
      scopes: ['user.read'], // Use 'user.read' scope
      account: account
    };

    return from(this.authService.acquireTokenSilent(tokenRequest)).pipe(
      switchMap((result) => {
        return new Observable<string>((observer) => {
          observer.next(result.accessToken);
          observer.complete();
        });
      })
    );
  }

  // Add this method to the PatientService class
  updatePatientMeetingDate(patientId: string, newDate: Date): Observable<void> {
    return this.getToken().pipe(
      switchMap((accessToken) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        });

        const body = {
          patientId: patientId,
          meetingDate: newDate
        };

        return this.http.post<void>(
          `${this.apiUrl}UpdatePatientMeetingDate` +
          (location.href.indexOf("localhost") > -1 ? '' :
            `?code=GCGAKH2QrgR95BMYkXd3P3AVSDwkWP0TyTc2wmDeFJ29AzFutF_M1g%3D%3D`),
          body,
          { headers }
        );
      })
    );
  }
}
