<!-- src/app/settings/settings.component.html -->
<div class="container mx-auto mt-8">
  <div class="flex items-center justify-between mb-4">
    <img src="https://cdn.prod.website-files.com/6659947600c0d43827718e80/6659981a9ac62339ee4f7413_logo.jpg"
         alt="Aisel Connect"
         class="h-10" />
    <div class="flex items-center space-x-4">
      <button mat-button (click)="navigateToHome()">Home</button>
      <a (click)="logout()" class="text-blue-600 cursor-pointer">Logout</a>
    </div>
  </div>

  <h2 class="text-2xl font-bold mb-4">Clinic Settings</h2>

  <form (ngSubmit)="saveSettings()" #settingsForm="ngForm" class="settings-form">
    <mat-form-field appearance="fill" class="w-full mb-2">
      <mat-label>Clinic Name</mat-label>
      <input matInput [(ngModel)]="clinicSettings.Clinic" name="clinic" readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-full mb-1">
      <mat-label>Invite SMS Template</mat-label>
      <textarea style="height:160px" matInput [(ngModel)]="clinicSettings.SmsTemplate" name="smsTemplate" required></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-full mb-1">
      <mat-label>SMS Sender</mat-label>
      <input matInput [(ngModel)]="clinicSettings.SmsSender" name="smsSender" required [pattern]="smsSenderPattern">
      <mat-error *ngIf="settingsForm.controls['smsSender']?.errors?.['pattern']">
        SMS sender must be alphanumeric and 12 characters or less
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-full mb-1">
      <mat-label>Default Therapist Name</mat-label>
      <input matInput [(ngModel)]="clinicSettings.DefaultTherapistName" name="defaultTherapistName">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-full mb-1">
      <mat-label>Reminder SMS Template</mat-label>
      <textarea style="height:160px" matInput [(ngModel)]="clinicSettings.ReminderSMSTemplate" name="reminderSMSTemplate" required></textarea>
    </mat-form-field>

    <button mat-raised-button color="primary" type="submit" [disabled]="!isFormChanged() || settingsForm.form.invalid">
      Save Changes
    </button>
  </form>
</div>
