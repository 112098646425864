<h2 mat-dialog-title>Notify Aisel Team</h2>
<mat-dialog-content>
  <mat-form-field appearance="fill" class="w-full">
    <textarea matInput [(ngModel)]="message" rows="6"></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="onSend()">Send</button>
</mat-dialog-actions>
