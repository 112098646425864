import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { SettingsComponent } from './settings/settings.component';
import { AuthGuard } from './AuthGuard';

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [MsalGuard, AuthGuard]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [MsalGuard, AuthGuard]
  },
  { path: '**', redirectTo: 'home' }
];
