// src/app/services/report.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { ReportCacheService } from './report-cache.service';
import { ComparisonResponse, ReportRequest, ReportResponse } from './models/report.model';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private apiUrl = location.href.indexOf("localhost") > -1
    ? 'http://localhost:7176/api/'
    : 'https://aiselclinic.azurewebsites.net/api/';

  constructor(
    private http: HttpClient,
    private authService: MsalService,
    private reportCache: ReportCacheService
  ) { }

  async generateReport(
    patient: any,
    selectedPrompt: any | null,
    formatPrompt: any | null
  ): Promise<string> {
    // Validate inputs
    if (!patient?.ID || !patient?.Name || !patient?.Blob || !patient?.StatusUpdatedDate) {
      throw new Error('Invalid patient data: Missing required fields');
    }

    if (selectedPrompt && (!selectedPrompt.name || !selectedPrompt.versionNumber ||
      !selectedPrompt.promptBlob || !selectedPrompt.lastUpdateDate)) {
      throw new Error('Invalid selected prompt data: Missing required fields');
    }

    if (formatPrompt && (!formatPrompt.name || !formatPrompt.versionNumber ||
      !formatPrompt.promptBlob || !formatPrompt.lastUpdateDate)) {
      throw new Error('Invalid format prompt data: Missing required fields');
    }

    // Create cache key
    const cacheKey = this.reportCache.getCacheKey(
      selectedPrompt?.name,
      selectedPrompt?.versionNumber,
      {
        name: formatPrompt?.name,
        version: formatPrompt?.versionNumber
      }
    );

    try {
      // Try to get from cache
      const cachedReport = this.reportCache.getReport(
        cacheKey,
        selectedPrompt ? new Date(selectedPrompt.lastUpdateDate) : new Date(),
        new Date(patient.StatusUpdatedDate)
      );

      if (cachedReport) {
        console.log('Report found in cache');
        return cachedReport;
      }

      // If not in cache, generate new report
      console.log('Generating new report from server');
      const request: ReportRequest = {
        patient: {
          id: patient.ID,
          name: patient.Name,
          blob: patient.Blob,
          statusUpdatedDate: patient.StatusUpdatedDate
        },
        selectedPrompt: selectedPrompt ? {
          name: selectedPrompt.name,
          versionNumber: selectedPrompt.versionNumber,
          promptBlob: selectedPrompt.promptBlob,
          lastUpdateDate: selectedPrompt.lastUpdateDate
        } : null,
        formatPrompt: formatPrompt ? {
          name: formatPrompt.name,
          versionNumber: formatPrompt.versionNumber,
          promptBlob: formatPrompt.promptBlob,
          lastUpdateDate: formatPrompt.lastUpdateDate
        } : null
      };

      const accessToken = await this.getToken().toPromise();
      if (!accessToken) {
        throw new Error('Failed to acquire access token');
      }

      const response = await this.callReportApi(request, accessToken).toPromise();

      if (!response || (response && !response.Success)) {
        throw "Failed to generate report";
      }

      if (!response.Report) {
        throw new Error('Server returned empty report');
      }

      // Store in cache
      this.reportCache.storeReport(
        cacheKey,
        response.Report,
        selectedPrompt ? new Date(selectedPrompt.lastUpdateDate) : new Date(),
        new Date(patient.StatusUpdatedDate)
      );

      return response.Report;
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`Failed to generate report: ${error.message}`);
      }
      throw new Error('Failed to generate report: Unknown error');
    }
  }

  private callReportApi(request: ReportRequest, accessToken: string): Observable<ReportResponse> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    });

    return this.http.post<ReportResponse>(
      `${this.apiUrl}GenerateReport` +
      (location.href.indexOf("localhost") > -1 ? '' : `?code=Jkkz79-J8c4i06Qf5JncwasVCmXXsl8DnK5TqrXbW8wKAzFuRdFLAg%3D%3D`),
      request,
      { headers }
    ).pipe(
      catchError(this.handleError)
    );
  }

  private getToken(): Observable<string> {
    const account = this.authService.instance.getActiveAccount();
    if (!account) {
      this.authService.loginRedirect();
      return new Observable<string>();
    }

    const tokenRequest = {
      scopes: ['user.read'],
      account: account
    };

    return from(this.authService.acquireTokenSilent(tokenRequest)).pipe(
      switchMap((result) => {
        return new Observable<string>((observer) => {
          observer.next(result.accessToken);
          observer.complete();
        });
      })
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An error occurred while generating the report';

    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Server returned code ${error.status}. Message: ${error.error?.message || error.message}`;
    }

    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }

  async compareReports(leftReport: string, rightReport: string): Promise<string> {
    try {
      const accessToken = await this.getToken().toPromise();
      if (!accessToken) {
        throw new Error('Failed to acquire access token');
      }

      const request = {
        leftReport,
        rightReport
      };

      const response = await this.callCompareReportsApi(request, accessToken).toPromise();

      if (!response || !response.success) {
        throw new Error(response?.error || 'Failed to compare reports');
      }

      return response.comparisonHtml;
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`Failed to compare reports: ${error.message}`);
      }
      throw new Error('Failed to compare reports: Unknown error');
    }
  }

  private callCompareReportsApi(request: any, accessToken: string): Observable<ComparisonResponse> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    });

    return this.http.post<ComparisonResponse>(
      `${this.apiUrl}CompareReports` +
      (location.href.indexOf("localhost") > -1 ? '' : `?code=bXuzt__G1lwJ3jrKpsRv0jdruaHEgkNVWZb63OfcgKwiAzFuEfGOkQ%3D%3D`),
      request,
      { headers }
    ).pipe(
      catchError(this.handleError)
    );
  }
}
