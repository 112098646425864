// app-prompt-editor.component.ts

import { Component, OnInit, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { PromptService } from '../prompt.service';
import { UtcToLocalPipe } from '../../UtcToLocalPipe';

interface DialogData {
  mode: 'edit' | 'add';
  promptName?: string;
  version?: number;
}

@Component({
  selector: 'app-prompt-editor',
  templateUrl: './app-prompt-editor.component.html',
  styleUrls: ['./app-prompt-editor.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatSnackBarModule,
    UtcToLocalPipe
  ]
})
export class PromptEditorComponent implements OnInit {
  promptForm: FormGroup;
  prompts: any[] = [];
  versions: number[] = [];
  selectedPrompt: { name: string } | null = null;
  selectedVersion: number | null = null;
  isNewPrompt: boolean = true;

  constructor(
    private fb: FormBuilder,
    private promptService: PromptService,
    private snackBar: MatSnackBar,
    private authService: MsalService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.promptForm = this.fb.group({
      name: ['', Validators.required],
      versionNumber: [{ value: 1, disabled: true }, Validators.required],
      promptBlob: ['', Validators.required],
      createdBy: [''],
      creationDate: [''],
      lastUpdateBy: [''],
      lastUpdateDate: ['']
    });
  }

  ngOnInit() {
    // First load all prompts
    this.loadPrompts().then(() => {
      if (this.data.mode === 'edit' && this.data.promptName && this.data.version) {
        // For edit mode, set up the left pane selections
        this.selectedPrompt = { name: this.data.promptName };
        this.selectedVersion = this.data.version;

        // Load versions for the selected prompt
        this.loadVersionsForPrompt(this.data.promptName).then(() => {
          // Ensure both promptName and version are defined before calling loadPromptVersion
          if (this.data.promptName && this.data.version) {
            this.loadPromptVersion(this.data.promptName, this.data.version);
          }
        });
      } else {
        // New prompt
        this.isNewPrompt = true;
        this.createNewPrompt();
      }
    });
  }

  loadPrompts(): Promise<void> {
    return new Promise((resolve) => {
      this.promptService.getPrompts().subscribe(
        (prompts) => {
          this.prompts = prompts;
          resolve();
        },
        (error) => {
          console.error('Error loading prompts:', error);
          this.snackBar.open('Error loading prompts', 'Close', { duration: 3000 });
          resolve();
        }
      );
    });
  }

  loadVersionsForPrompt(promptName: string): Promise<void> {
    return new Promise((resolve) => {
      this.promptService.getPromptVersions(promptName).subscribe(
        (versions) => {
          this.versions = versions;
          resolve();
        },
        (error) => {
          console.error('Error loading prompt versions:', error);
          this.snackBar.open('Error loading prompt versions', 'Close', { duration: 3000 });
          resolve();
        }
      );
    });
  }

  onPromptSelect(event: { value: string }) {
    const promptName = event.value;
    this.isNewPrompt = false;

    this.loadVersionsForPrompt(promptName).then(() => {
      if (this.versions.length > 0) {
        const latestVersion = Math.max(...this.versions);
        this.selectedVersion = latestVersion;
        this.loadPromptVersion(promptName, latestVersion);
      }
    });
  }

  loadPromptVersion(promptName: string, version: number) {
    this.promptService.getPrompt(promptName, version).subscribe(
      (prompt) => {
        this.selectedPrompt = { name: promptName };
        this.selectedVersion = version;
        this.promptForm.patchValue({
          name: prompt.name,
          versionNumber: version,
          promptBlob: prompt.promptBlob,
          createdBy: prompt.createdBy,
          creationDate: new Date(prompt.creationDate).toLocaleString(),
          lastUpdateBy: prompt.lastUpdateBy,
          lastUpdateDate: new Date(prompt.lastUpdateDate).toLocaleString()
        });
        this.isNewPrompt = false;
      },
      (error) => {
        console.error('Error loading prompt:', error);
        this.snackBar.open('Error loading prompt', 'Close', { duration: 3000 });
      }
    );
  }

  createNewPrompt() {
    this.isNewPrompt = true;
    this.selectedPrompt = null;
    this.selectedVersion = null;
    this.versions = [];
    this.promptForm.reset({
      name: '',
      versionNumber: 1,
      promptBlob: '',
      createdBy: '',
      creationDate: '',
      lastUpdateBy: '',
      lastUpdateDate: ''
    });
    this.promptForm.get('versionNumber')!.disable();
  }

  savePrompt(asNewVersion: boolean = false) {
    if (this.promptForm.valid) {
      const formValues = this.promptForm.getRawValue();
      let promptData = {
        ...formValues,
        lastUpdateBy: this.getCurrentUserEmail()
      };

      if (asNewVersion) {
        promptData.versionNumber = Math.max(...this.versions, 0) + 1;
        promptData.createdBy = this.getCurrentUserEmail();
      } else if (this.isNewPrompt) {
        promptData.versionNumber = 1;
        promptData.createdBy = this.getCurrentUserEmail();
      } else {
        promptData.versionNumber = formValues.versionNumber;
      }

      this.promptService.savePrompt(promptData).subscribe(
        (response) => {
          this.snackBar.open('Prompt saved successfully', 'Close', { duration: 3000 });

          // Reload the prompt to get fresh data
          if (!this.isNewPrompt && !asNewVersion) {
            this.loadPromptVersion(promptData.name, promptData.versionNumber);
          }

          if (asNewVersion || this.isNewPrompt) {
            this.promptService.getPrompts().subscribe(prompts => {
              this.prompts = prompts;
              this.selectedPrompt = { name: promptData.name };

              this.promptService.getPromptVersions(promptData.name).subscribe(versions => {
                this.versions = versions;
                this.selectedVersion = promptData.versionNumber;
                // Load the fresh data for the new version
                this.loadPromptVersion(promptData.name, promptData.versionNumber);
              });
            });
          }

          this.isNewPrompt = false;
        },
        (error) => {
          console.error('Error saving prompt:', error);
          this.snackBar.open('Error saving prompt', 'Close', { duration: 3000 });
        }
      );
    }
  }

  getCurrentUserEmail(): string {
    const account = this.authService.instance.getActiveAccount();
    return account ? account.username : '';
  }
}
