<div class="flex items-center gap-4 mb-4">
  <!-- Left Prompt Section -->
  <div class="flex items-center gap-2">
    <mat-form-field class="w-64">
      <mat-label>Left</mat-label>
      <mat-select [(ngModel)]="leftPrompt" (selectionChange)="onLeftPromptSelect($event)">
        <mat-option value="current">Current Report</mat-option>
        <mat-option *ngFor="let prompt of prompts" [value]="prompt.name">
          {{ prompt.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-24">
      <mat-label>Ver</mat-label>
      <mat-select [(ngModel)]="leftVersion"
                  (selectionChange)="onLeftVersionSelect($event)"
                  [disabled]="leftPrompt === 'current'">
        <mat-option *ngFor="let version of leftVersions" [value]="version">
          v{{ version }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- Right Prompt Section -->
  <div class="flex items-center gap-2">
    <mat-form-field class="w-64">
      <mat-label>Right</mat-label>
      <mat-select [(ngModel)]="rightPrompt" (selectionChange)="onRightPromptSelect($event)">
        <mat-option *ngFor="let prompt of prompts" [value]="prompt.name">
          {{ prompt.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-24">
      <mat-label>Ver</mat-label>
      <mat-select [(ngModel)]="rightVersion" (selectionChange)="onRightVersionSelect($event)">
        <mat-option *ngFor="let version of rightVersions" [value]="version">
          v{{ version }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-icon-button
            (click)="editPrompt('right')"
            color="primary"
            matTooltip="Edit Right Prompt">
      <mat-icon class="text-blue-600">edit</mat-icon>
    </button>
  </div>

  <!-- Format Prompt Section -->
  <div class="flex items-center gap-2">
    <mat-form-field class="w-64">
      <mat-label>Format</mat-label>
      <mat-select [(ngModel)]="formatPrompt" (selectionChange)="onFormatPromptSelect($event)">
        <mat-option *ngFor="let prompt of prompts" [value]="prompt.name">
          {{ prompt.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-24">
      <mat-label>Ver</mat-label>
      <mat-select [(ngModel)]="formatVersion" (selectionChange)="onFormatVersionSelect($event)">
        <mat-option *ngFor="let version of formatVersions" [value]="version">
          v{{ version }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-icon-button
            (click)="editPrompt('format')"
            color="primary"
            matTooltip="Edit Format Prompt">
      <mat-icon class="text-blue-600">edit</mat-icon>
    </button>
    <button mat-icon-button
            (click)="addNewPrompt()"
            color="primary"
            matTooltip="Add New Prompt">
      <mat-icon class="text-blue-600">add</mat-icon>
    </button>
  </div>
</div>
