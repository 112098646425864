<div class="login-container">
  <mat-card class="login-card">
    <mat-card-header>
      <mat-card-title class="login-title">Welcome to Aisel Clinic</mat-card-title>
    </mat-card-header>
    <mat-card-actions>
      <button mat-raised-button color="primary" class="login-button" (click)="login()">
        Login
      </button>
    </mat-card-actions>
  </mat-card>
</div>
