// src/app/report-cache.service.ts

import { Injectable } from '@angular/core';

interface CachedReport {
  report: string;
  createdAt: number;
  promptLastUpdated: number;
  patientLastUpdated: number;
}

@Injectable({
  providedIn: 'root'
})
export class ReportCacheService {
  private readonly STORAGE_PREFIX = 'report_cache_';

  constructor() {
    // Clean up old cache items on service initialization
    this.cleanupOldCache();
  }

  storeReport(
    key: string,
    report: string,
    promptLastUpdated: Date,
    patientLastUpdated: Date
  ): void {
    const cacheItem: CachedReport = {
      report,
      createdAt: Date.now(),
      promptLastUpdated: promptLastUpdated.getTime(),
      patientLastUpdated: patientLastUpdated.getTime()
    };

    localStorage.setItem(
      this.STORAGE_PREFIX + key,
      JSON.stringify(cacheItem)
    );
  }

  getReport(
    key: string,
    promptLastUpdated: Date,
    patientLastUpdated: Date
  ): string | null {
    const cached = this.getCacheItem(key);

    if (!cached) {
      return null;
    }

    // Check if cache is still valid based on update dates
    if (cached.promptLastUpdated < promptLastUpdated.getTime() ||
      cached.patientLastUpdated < patientLastUpdated.getTime()) {
      this.removeReport(key);
      return null;
    }

    return cached.report;
  }

  getCacheKey(
    promptName: string | null,
    version: number | null,
    formatPrompt?: {
      name: string | null,
      version: number | null
    }
  ): string {
    return [
      promptName || 'current',
      version || 'latest',
      formatPrompt?.name || 'noformat',
      formatPrompt?.version || 'latest'
    ].join('_');
  }

  private getCacheItem(key: string): CachedReport | null {
    const item = localStorage.getItem(this.STORAGE_PREFIX + key);
    if (!item) {
      return null;
    }

    try {
      return JSON.parse(item) as CachedReport;
    } catch {
      this.removeReport(key);
      return null;
    }
  }

  removeReport(key: string): void {
    localStorage.removeItem(this.STORAGE_PREFIX + key);
  }

  clearCache(): void {
    Object.keys(localStorage)
      .filter(key => key.startsWith(this.STORAGE_PREFIX))
      .forEach(key => localStorage.removeItem(key));
  }

  private cleanupOldCache(): void {
    const cacheKeys = Object.keys(localStorage)
      .filter(key => key.startsWith(this.STORAGE_PREFIX));

    for (const key of cacheKeys) {
      const item = this.getCacheItem(key.replace(this.STORAGE_PREFIX, ''));
      if (!item) {
        localStorage.removeItem(key);
      }
    }
  }
}
