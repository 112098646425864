// src/app/report-dialog/report-dialog.component.ts
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  selector: 'app-report-dialog',
  template: `
    <div class="dialog-container">
      <h1 mat-dialog-title class="dialog-title">Report</h1>
      <div class="dialog-content" [innerHTML]="formattedReport">
      </div>
      <div mat-dialog-actions class="dialog-actions">
        <button mat-button (click)="copyReport()">Copy</button>
        <button mat-button mat-dialog-close>Close</button>
      </div>
    </div>
  `,
  styles: [`
    .dialog-container {
      display: flex;
      flex-direction: column;
      height: 90vh;
      max-height: 90vh;
    }

    .dialog-title {
      flex: 0 0 auto;
      margin: 0;
      padding: 16px 24px;
      background: white;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    .dialog-content {
      flex: 1 1 auto;
      padding: 24px;
      overflow-y: auto;
      margin: 0;
      white-space: pre-wrap;
      font-family: monospace;
      line-height: 1.5;
      background-color: #f5f5f5;
    }

    .dialog-actions {
      flex: 0 0 auto;
      padding: 8px 24px;
      margin: 0;
      background: white;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }

    ::ng-deep .mat-mdc-dialog-container {
      padding: 0 !important;
      overflow: hidden !important;
    }
  `],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatSnackBarModule,
  ],
})
export class ReportDialogComponent {
  formattedReport: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { report: string, onCopy: () => void },
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ReportDialogComponent>
  ) {
    this.formattedReport = this.formatReport(data.report || '');

    // Configure dialog to take up most of the screen
    this.dialogRef.updateSize('90vw', '90vh');
  }

  private formatReport(report: string): string {
    // Convert newlines to <br> tags and preserve whitespace
    return report;
  }

  copyReport() {
    if (this.data.onCopy) {
      this.data.onCopy();
    }
  }
}
