<div class="flex h-full">
  <!-- Left Pane -->
  <div class="w-80 p-4 bg-gray-100 border-r">
    <h2 class="text-xl font-bold mb-4">Prompt Editor</h2>
    <button mat-raised-button
            color="primary"
            (click)="createNewPrompt()"
            class="w-full mb-4">
      Create New Prompt
    </button>
    <mat-form-field class="w-full">
      <mat-label>Select Prompt</mat-label>
      <mat-select [value]="selectedPrompt?.name"
                  (selectionChange)="onPromptSelect($event)">
        <mat-option *ngFor="let prompt of prompts"
                    [value]="prompt.name">
          {{ prompt.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="versions.length > 0"
                    class="w-full mt-4">
      <mat-label>Select Version</mat-label>
      <mat-select [value]="selectedVersion"
                  (selectionChange)="loadPromptVersion(selectedPrompt?.name || '', $event.value)">
        <mat-option *ngFor="let version of versions"
                    [value]="version">
          Version {{ version }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- Right Pane -->
  <div class="flex-grow p-4 flex flex-col">
    <form [formGroup]="promptForm"
          (ngSubmit)="savePrompt()"
          class="flex flex-col h-full">
      <div class="grid grid-cols-2 gap-4 mb-4">
        <mat-form-field>
          <mat-label>Prompt Name</mat-label>
          <input matInput formControlName="name" required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Version</mat-label>
          <input matInput formControlName="versionNumber" type="number" required>
        </mat-form-field>
      </div>

      <mat-form-field class="flex-grow prompt-field">
        <mat-label>Prompt</mat-label>
        <textarea matInput formControlName="promptBlob" required></textarea>
      </mat-form-field>

      <div class="grid grid-cols-2 gap-4 mt-4 text-sm text-gray-600">
        <div>
          <p>Created by: {{ promptForm.get('createdBy')?.value }}</p>
          <p>Creation date: {{ promptForm.get('creationDate')?.value | utcToLocal }}</p>
        </div>
        <div>
          <p>Last updated by: {{ promptForm.get('lastUpdateBy')?.value }}</p>
          <p>Last update date: {{ promptForm.get('lastUpdateDate')?.value | utcToLocal }}</p>
        </div>
      </div>

      <div class="flex justify-end mt-4 space-x-4">
        <button mat-raised-button
                color="primary"
                type="submit"
                [disabled]="!promptForm.valid">
          Save Changes
        </button>
        <button mat-raised-button
                color="accent"
                type="button"
                (click)="savePrompt(true)"
                [disabled]="!promptForm.valid || isNewPrompt">
          Save as New Version
        </button>
      </div>
    </form>
  </div>
</div>
