// app-prompt-comparison.component.ts
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PromptService } from '../prompt.service';
import { PromptEditorComponent } from '../app-prompt-editor/app-prompt-editor.component';
import { Prompt } from '../models/prompt.model';

@Component({
  selector: 'app-prompt-comparison',
  templateUrl: './app-prompt-comparison.component.html',
  styleUrls: ['./app-prompt-comparison.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule
  ]
})
export class PromptComparisonComponent {
  // Arrays and selections
  prompts: Prompt[] = [];
  leftVersions: number[] = [];
  rightVersions: number[] = [];
  formatVersions: number[] = [];

  // Selected prompt names
  leftPrompt: string = 'current';
  rightPrompt: string | null = null;
  formatPrompt: string | null = null;

  // Selected versions
  leftVersion: number | null = null;
  rightVersion: number | null = null;
  formatVersion: number | null = null;

  // Full prompt data
  leftPromptData: Prompt | null = null;
  rightPromptData: Prompt | null = null;
  formatPromptData: Prompt | null = null;

  constructor(
    private promptService: PromptService,
    private dialog: MatDialog
  ) {
    this.loadPrompts();
  }

  loadPrompts() {
    this.promptService.getPrompts().subscribe(prompts => {
      this.prompts = prompts;

      // Find the latest prompts if none are selected
      const nonFormatPrompts = prompts.filter(p => !p.name.toLowerCase().includes('format'));
      const formatPrompts = prompts.filter(p => p.name.toLowerCase().includes('format'));

      // Set right prompt if not already set
      if (!this.rightPrompt && nonFormatPrompts.length > 0) {
        const latestNonFormat = nonFormatPrompts[nonFormatPrompts.length - 1];
        this.rightPrompt = latestNonFormat.name;
        this.onRightPromptSelect({ value: latestNonFormat.name });
      }

      // Set format prompt if not already set
      if (!this.formatPrompt && formatPrompts.length > 0) {
        const latestFormat = formatPrompts[formatPrompts.length - 1];
        this.formatPrompt = latestFormat.name;
        this.onFormatPromptSelect({ value: latestFormat.name });
      }
    });
  }

  onLeftPromptSelect(event: { value: string }) {
    if (event.value === 'current') {
      this.leftPromptData = null;
      this.leftVersion = null;
      this.leftVersions = [];
      return;
    }

    this.promptService.getPromptVersions(event.value).subscribe(versions => {
      this.leftVersions = versions;
      if (versions.length > 0) {
        this.leftVersion = Math.max(...versions);
        this.loadPromptData(event.value, this.leftVersion, 'left');
      }
    });
  }

  onRightPromptSelect(event: { value: string }) {
    if (!event.value) return;

    this.promptService.getPromptVersions(event.value).subscribe(versions => {
      this.rightVersions = versions;
      if (versions.length > 0) {
        this.rightVersion = Math.max(...versions);
        this.loadPromptData(event.value, this.rightVersion, 'right');
      }
    });
  }

  onFormatPromptSelect(event: { value: string }) {
    if (!event.value) return;

    this.promptService.getPromptVersions(event.value).subscribe(versions => {
      this.formatVersions = versions;
      if (versions.length > 0) {
        this.formatVersion = Math.max(...versions);
        this.loadPromptData(event.value, this.formatVersion, 'format');
      }
    });
  }

  onLeftVersionSelect(event: { value: number }) {
    if (event.value && this.leftPrompt && this.leftPrompt !== 'current') {
      this.leftVersion = event.value;
      this.loadPromptData(this.leftPrompt, event.value, 'left');
    }
  }

  onRightVersionSelect(event: { value: number }) {
    if (event.value && this.rightPrompt) {
      this.rightVersion = event.value;
      this.loadPromptData(this.rightPrompt, event.value, 'right');
    }
  }

  onFormatVersionSelect(event: { value: number }) {
    if (event.value && this.formatPrompt) {
      this.formatVersion = event.value;
      this.loadPromptData(this.formatPrompt, event.value, 'format');
    }
  }

  loadPromptData(promptName: string, version: number, type: 'left' | 'right' | 'format') {
    if (!promptName || version === null) return;

    this.promptService.getPrompt(promptName, version).subscribe(prompt => {
      if (!prompt) return;

      switch (type) {
        case 'left':
          this.leftPromptData = prompt;
          break;
        case 'right':
          this.rightPromptData = prompt;
          break;
        case 'format':
          this.formatPromptData = prompt;
          break;
      }
    });
  }

  reloadEverything() {
    // Clear selections to force a fresh reload
    const savedLeftPrompt = this.leftPrompt;
    const savedRightPrompt = this.rightPrompt;
    const savedFormatPrompt = this.formatPrompt;

    // Reset all values
    this.leftPrompt = 'current';
    this.rightPrompt = null;
    this.formatPrompt = null;
    this.leftVersion = null;
    this.rightVersion = null;
    this.formatVersion = null;
    this.leftVersions = [];
    this.rightVersions = [];
    this.formatVersions = [];
    this.leftPromptData = null;
    this.rightPromptData = null;
    this.formatPromptData = null;

    // Load all prompts and their data fresh
    this.promptService.getPrompts().subscribe(prompts => {
      this.prompts = prompts;

      // Restore right prompt if it still exists
      if (savedRightPrompt) {
        if (prompts.some(p => p.name === savedRightPrompt)) {
          this.rightPrompt = savedRightPrompt;
          this.onRightPromptSelect({ value: savedRightPrompt });
        }
      }

      // Restore format prompt if it still exists
      if (savedFormatPrompt) {
        if (prompts.some(p => p.name === savedFormatPrompt)) {
          this.formatPrompt = savedFormatPrompt;
          this.onFormatPromptSelect({ value: savedFormatPrompt });
        }
      }

      // Restore left prompt if it was not 'current' and still exists
      if (savedLeftPrompt && savedLeftPrompt !== 'current') {
        if (prompts.some(p => p.name === savedLeftPrompt)) {
          this.leftPrompt = savedLeftPrompt;
          this.onLeftPromptSelect({ value: savedLeftPrompt });
        }
      }
    });
  }

  editPrompt(type: 'right' | 'format') {
    const promptName = type === 'right' ? this.rightPrompt : this.formatPrompt;
    const version = type === 'right' ? this.rightVersion : this.formatVersion;

    if (!promptName || version === null) return;

    const dialogRef = this.dialog.open(PromptEditorComponent, {
      width: '90%',
      height: '90%',
      data: {
        mode: 'edit',
        promptName,
        version
      }
    });

    dialogRef.afterClosed().subscribe(result => {
        this.reloadEverything();
    });
  }

  addNewPrompt() {
    const dialogRef = this.dialog.open(PromptEditorComponent, {
      width: '90%',
      height: '90%',
      data: { mode: 'add' }
    });

    dialogRef.afterClosed().subscribe(result => {
        this.reloadEverything();
    });
  }
}
