// src/app/clinic-settings.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ClinicSettings } from './models/clinic-settings.model';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class ClinicSettingsService {
  //private apiUrl = 'http://localhost:7176/api/';
  //private apiUrl = 'https://aiselclinic.azurewebsites.net/api/';
  private apiUrl = location.href.indexOf("localhost") > -1 ? 'http://localhost:7176/api/' : 'https://aiselclinic.azurewebsites.net/api/';

  constructor(private http: HttpClient, private authService: MsalService) { }

  getClinicSettings(): Observable<ClinicSettings> {
    return this.getToken().pipe(
      switchMap((accessToken: string) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${accessToken}`
        });
        return this.http.get<ClinicSettings>(`${this.apiUrl}GetClinicSettings` + (location.href.indexOf("localhost") > -1 ? '' : `?code=eNfgP_0PRBvEZYF93oLfUsgwaGqRPsh5hXZa0I5pqC1rAzFuvl2ASQ%3D%3D`), { headers });
      })
    );
  }

  updateClinicSettings(settings: ClinicSettings): Observable<void> {
    return this.getToken().pipe(
      switchMap((accessToken: string) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        });
        return this.http.post<void>(`${this.apiUrl}UpdateClinicSettings` + (location.href.indexOf("localhost") > -1 ? '' : `?code=hVZ5t7GpD8RYccGItiC2mQs9O6W54vupht150aJ66pnUAzFu-vbwtA%3D%3D`), settings, { headers });
      })
    );
  }

  private getToken(): Observable<string> {
    const account = this.authService.instance.getActiveAccount();
    if (!account) {
      this.authService.loginRedirect();
      return new Observable<string>();
    }

    const tokenRequest = {
      scopes: ['user.read'],
      account: account
    };

    return from(this.authService.acquireTokenSilent(tokenRequest)).pipe(
      switchMap((result: AuthenticationResult) => {
        return new Observable<string>((observer) => {
          observer.next(result.accessToken);
          observer.complete();
        });
      })
    );
  }
}
