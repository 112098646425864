// src/app/settings/settings.component.ts
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClinicSettingsService } from '../clinic-settings.service';
import { ClinicSettings } from '../models/clinic-settings.model';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule
  ]
})
export class SettingsComponent implements OnInit {
  clinicSettings: ClinicSettings = { Clinic: '', SmsTemplate: '', SmsSender: '', DefaultTherapistName: null, ReminderSMSTemplate: '' };
  originalSettings: ClinicSettings = { Clinic: '', SmsTemplate: '', SmsSender: '', DefaultTherapistName: null, ReminderSMSTemplate: '' };
  isLoading = false;
  smsSenderPattern = '^[A-Za-z0-9]{1,12}$'; // Pattern for alphanumeric, max 12 characters

  constructor(
    private clinicSettingsService: ClinicSettingsService,
    private snackBar: MatSnackBar,
    private authService: MsalService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loadClinicSettings();
  }

  loadClinicSettings() {
    this.isLoading = true;
    this.clinicSettingsService.getClinicSettings().subscribe({
      next: (settings) => {
        this.clinicSettings = { ...settings };
        this.originalSettings = { ...settings };
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Error loading clinic settings:', error);
        this.snackBar.open('Failed to load clinic settings', 'Close', { duration: 3000 });
        this.isLoading = false;
      }
    });
  }

  saveSettings() {
    this.isLoading = true;
    this.clinicSettingsService.updateClinicSettings(this.clinicSettings).subscribe({
      next: () => {
        this.snackBar.open('Settings updated successfully', 'Close', { duration: 3000 });
        this.originalSettings = { ...this.clinicSettings };
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Error updating clinic settings:', error);
        this.snackBar.open('Failed to update settings', 'Close', { duration: 3000 });
        this.isLoading = false;
      }
    });
  }

  isFormChanged(): boolean {
    return JSON.stringify(this.clinicSettings) !== JSON.stringify(this.originalSettings);
  }

  logout() {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: location.href.indexOf("localhost") > -1 ? 'http://localhost:4200' : 'https://clinic.aisel.co',
    });
  }

  navigateToHome() {
    this.router.navigate(['/home']);
  }
}
