// report-review-dialog.component.ts
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Patient } from '../models/patient.model';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-report-review-dialog',
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatDialogModule],
  template: `
    <div class="h-full flex flex-col">
      <h2 mat-dialog-title>Review Report</h2>
      <mat-dialog-content class="flex-grow overflow-hidden">
        <div class="h-full flex space-x-4">
          <div class="w-1/2 flex flex-col">
            <h3 class="text-xl font-semibold mb-2">Chat History</h3>
            <div class="flex-grow border p-4 overflow-y-auto">
              <div *ngFor="let message of chatMessages" class="mb-2">
                <div [ngClass]="{'text-blue-600': message.type === 'AccountToCustomerMessage', 'text-green-600': message.type === 'CustomerToAccountMessage'}">
                  {{ message.type === 'AccountToCustomerMessage' ? 'Bot' : 'User' }}:
                </div>
                <div>{{ message.body }}</div>
              </div>
            </div>
          </div>
          <div class="w-1/2 flex flex-col">
            <h3 class="text-xl font-semibold mb-2">{{ titleText }}</h3>
            <textarea [(ngModel)]="reportReviewed" 
                      class="flex-grow w-full p-2 border rounded" 
                      [readOnly]="!isEditable">
            </textarea>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-button (click)="onCancel()">Close</button>
        <button mat-button 
                *ngIf="isEditable" 
                color="primary" 
                (click)="onSave()">Save</button>
      </mat-dialog-actions>
    </div>
  `,
  styles: [`
    :host {
      display: flex;
      flex-direction: column;
      height: 90vh;
    }
    mat-dialog-content {
      max-height: none;
    }
  `]
})
export class ReportReviewDialogComponent {
  reportReviewed: string;
  chatMessages: any[] = [];
  isEditable: boolean = false;
  titleText: string = 'Report';

  constructor(
    public dialogRef: MatDialogRef<ReportReviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      patient: Patient,
      report?: string,
      isEditable?: boolean,
      title?: string
    }
  ) {
    this.isEditable = data.isEditable ?? true;
    this.titleText = data.title ?? 'Reviewed Report';
    this.reportReviewed = data.report || data.patient.ReportReviewed || data.patient.Report;

    if (data.patient.Blob) {
      try {
        const blobData = JSON.parse(data.patient.Blob);
        this.chatMessages = blobData.messages || [];
      } catch (error) {
        console.error('Error parsing Blob data:', error);
      }
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
      this.dialogRef.close({ reportReviewed: this.reportReviewed });
  }
}
