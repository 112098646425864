// src/app/home/home.component.ts

import { Component, OnInit, ViewChild, AfterViewInit, LOCALE_ID } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Patient } from '../models/patient.model';
import { PatientService } from '../patient.service';
import { ClinicSettingsService } from '../clinic-settings.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { ReportDialogComponent } from '../report-dialog/report-dialog.component';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { NotifyAiselDialogComponent } from '../notify-aisel-dialog/notify-aisel-dialog.component';
import { ReportReviewDialogComponent } from '../report-review-dialog/report-review-dialog.component'; // You'll need to create this component
import { PromptEditorComponent } from '../app-prompt-editor/app-prompt-editor.component';
import { UtcToLocalPipe } from '../../UtcToLocalPipe';
import { PromptComparisonComponent } from '../app-prompt-comparison/app-prompt-comparison.component';
import { PromptService } from '../prompt.service';
import { Prompt } from '../models/prompt.model';
import { ReportService } from '../report.service';

export class CustomDateAdapter extends NativeDateAdapter {
  override format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${this._to2digit(day)}/${this._to2digit(month)}/${year}`;
    }
    return date.toDateString();
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'input',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [
    MatCheckboxModule,
    MatTooltipModule,
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSelectModule,
    UtcToLocalPipe,
    MatMenuModule,
    PromptComparisonComponent
  ],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: LOCALE_ID, useValue: 'en-GB' },
    PromptService 
  ],
})


export class HomeComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'Phone',
    'Name',
    /*'DateOfCreation',*/
    'DoctorName',
    'MeetingDate',
    'Status',
    'Actions',
    'MoreActions'
  ];
  dataSource: MatTableDataSource<Patient> = new MatTableDataSource<Patient>();
  isLoading = false;
  error: string | null = null;

  filterValue = '';

  minDate: Date;
  meetingTime: string = '';

  statusOptions: string[] = [
    '1 Created',
    '2 SMS sent',
    '2e SMS attempt failed',
    '3 Sms opened',
    '4 Conversation started',
    '5 Conversation ended',
    '6 Report in review',
    '7 Report ready',
    '8 Report copied',
    '9 Archived',
  ];

  reviewerStatusOptions: string[] = [
    '6 Report in review',
    '7 Report ready',
    '8 Report copied',
    '9 Archived',
  ];

  selectedStatuses: string[] = [];
  allSelected = true;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  newPatient: Partial<Patient> = {};
  showArchived: boolean = false;
  defaultTherapistName: string = '';
  isRefreshing = false;

  isReviewer: boolean = false;
  selectedPatient: Patient | null = null;
  chatMessages: any[] = [];
  showCreatePrompts: boolean = false;

  @ViewChild(PromptComparisonComponent) promptComparison!: PromptComparisonComponent;

  showAll: boolean = false; // New property for developer view

  // Add these properties to the HomeComponent class
  isEditingDate: { [key: string]: boolean } = {};
  editDateValue: { [key: string]: Date } = {};
  editTimeValue: { [key: string]: string } = {};

  // Add these methods to the HomeComponent class
  getCurrentUserRole(): string {
    return this.patientService.getCurrentUserRole();
  }

  startEditingDate(patient: Patient) {
    // Set the initial values
    if (!patient.MeetingDate) {
      // If no meeting date is set, use current date
      const now = new Date();
      this.editDateValue[patient.ID] = now;
      this.editTimeValue[patient.ID] = this.datePipe.transform(now, 'HH:mm') || '';
    } else {
      const meetingDate = new Date(patient.MeetingDate as string | number | Date);
      this.editDateValue[patient.ID] = meetingDate;
      this.editTimeValue[patient.ID] = this.datePipe.transform(meetingDate, 'HH:mm') || '';
    }
    this.isEditingDate[patient.ID] = true;
  }

  cancelEditingDate(patient: Patient) {
    this.isEditingDate[patient.ID] = false;
    delete this.editDateValue[patient.ID];
    delete this.editTimeValue[patient.ID];
  }

  updateMeetingDate(patient: Patient) {
    if (!this.editDateValue[patient.ID] || !this.editTimeValue[patient.ID]) {
      this.snackBar.open('Please select both date and time', 'Close', { duration: 3000 });
      return;
    }

    // Create a new date by combining the date and time
    const [hours, minutes] = this.editTimeValue[patient.ID].split(':').map(Number);
    const newDate = new Date(this.editDateValue[patient.ID]);
    newDate.setHours(hours, minutes, 0, 0);

    // Add the API call here when we implement it
    this.patientService.updatePatientMeetingDate(patient.ID, newDate).subscribe({
      next: () => {
        // Update the local data
        patient.MeetingDate = newDate;
        this.snackBar.open('Meeting date updated successfully', 'Close', { duration: 3000 });
        this.cancelEditingDate(patient);
        this.loadPatients(); // Refresh the list
      },
      error: (error) => {
        console.error('Error updating meeting date:', error);
        this.snackBar.open('Failed to update meeting date', 'Close', { duration: 3000 });
      }
    });
  }

  constructor(
    private patientService: PatientService,
    private clinicSettingsService: ClinicSettingsService,
    private dialog: MatDialog,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private authService: MsalService,
    private router: Router,
    private promptService: PromptService, 
    private reportService: ReportService  
  ) {
    const currentDate = new Date();
    this.minDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  }

  // Add developerColumns property
  developerColumns: string[] = ['star', 'Name', 'Status', 'Actions'];

  isStarred(patientId: string): boolean {
    return patientId.startsWith('_');
  }

  // Add getDisplayedColumns method
  getDisplayedColumns(): string[] {
    if (this.showCreatePrompts) {
      return this.developerColumns;
    } else if (this.isReviewer) {
      return ['Name', 'MeetingDate', 'Status', 'Actions'];
    }
    return this.displayedColumns;
  }

  ngOnInit() {
    this.loadPatients();
    this.loadDefaultTherapistName();
    this.isReviewer = this.patientService.isReviewer();

    this.isReviewer = this.patientService.isReviewer();
    this.checkUserRole();

    if (this.showCreatePrompts) {
      this.displayedColumns = this.developerColumns;
    }
    else if (this.isReviewer) {
      this.statusOptions = this.reviewerStatusOptions;
      this.displayedColumns = this.displayedColumns.filter(col => col !== 'DoctorName' && col !== 'Phone');
    }

    this.selectedStatuses = [...this.statusOptions];

    this.refreshStatus();

    if (!this.meetingTime) {
      const now = new Date();
      this.meetingTime = `11:00`;
    }

    this.dataSource.sortingDataAccessor = (item: Patient, property: string) => {
      switch (property) {
        case 'Status':
          return item.Status || '';
        case 'StatusUpdatedDate':
          return item.StatusUpdatedDate ? new Date(item.StatusUpdatedDate).getTime() : 0;
        case 'MeetingDate':
          return item.MeetingDate ? new Date(item.MeetingDate).getTime() : 0;
        default:
          return (item as any)[property];
      }
    };

    this.dataSource.sortData = (data: Patient[], sort: MatSort) => {
      return data.sort((a, b) => {
        let comparatorResult = 0;
        const isAsc = sort.direction === 'asc';

        switch (sort.active) {
          case 'Status':
            comparatorResult = (a.Status || '').localeCompare(b.Status || '');
            if (comparatorResult === 0) {
              // If statuses are the same, sort by StatusUpdatedDate
              const dateA = a.StatusUpdatedDate ? new Date(a.StatusUpdatedDate).getTime() : 0;
              const dateB = b.StatusUpdatedDate ? new Date(b.StatusUpdatedDate).getTime() : 0;
              comparatorResult = dateA - dateB;
            }
            break;
          case 'Name':
            comparatorResult = (a.Name || '').localeCompare(b.Name || '');
            break;
          case 'Phone':
            comparatorResult = (a.Phone || '').localeCompare(b.Phone || '');
            break;
          case 'DoctorName':
            comparatorResult = (a.DoctorName || '').localeCompare(b.DoctorName || '');
            break;
          case 'MeetingDate':
            const dateA = a.MeetingDate ? new Date(a.MeetingDate).getTime() : 0;
            const dateB = b.MeetingDate ? new Date(b.MeetingDate).getTime() : 0;
            comparatorResult = dateA - dateB;
            break;
          default:
            comparatorResult = 0;
        }

        return comparatorResult * (isAsc ? 1 : -1);
      });
    };

  }


  checkUserRole() {
    const account = this.authService.instance.getActiveAccount();
    if (account && account.username.startsWith('dev_')) {
      this.showCreatePrompts = true;
    }
  }

  dateFilter = (date: Date | null): boolean => {
    return date ? date >= this.minDate : false;
  };


  handleShowReport(patient: Patient, isLeft: boolean) {
    // Validate patient data
    if (!patient.ID || !patient.Name || !patient.StatusUpdatedDate) {
      this.snackBar.open('Missing patient data', 'Close', { duration: 3000 });
      return;
    }
    if (!patient.Blob) {
      this.snackBar.open('Patient has no conversation data', 'Close', { duration: 3000 });
      return;
    }

    // Validate right prompt (always required)
    if (!this.promptComparison?.rightPrompt) {
      this.snackBar.open('Please select right prompt', 'Close', { duration: 3000 });
      return;
    }
    if (!this.promptComparison?.rightVersion) {
      this.snackBar.open('Please select right prompt version', 'Close', { duration: 3000 });
      return;
    }
    if (!this.promptComparison?.rightPromptData) {
      this.snackBar.open('Right prompt data not loaded', 'Close', { duration: 3000 });
      return;
    }

    // Validate left prompt if not using Current Report
    if (!isLeft && this.promptComparison?.leftPrompt !== 'current') {
      if (!this.promptComparison?.leftVersion) {
        this.snackBar.open('Please select left prompt version', 'Close', { duration: 3000 });
        return;
      }
      if (!this.promptComparison?.leftPromptData) {
        this.snackBar.open('Left prompt data not loaded', 'Close', { duration: 3000 });
        return;
      }
    }

    // Validate format prompt if selected
    if (this.promptComparison?.formatPrompt) {
      if (!this.promptComparison?.formatVersion) {
        this.snackBar.open('Please select format prompt version', 'Close', { duration: 3000 });
        return;
      }
      if (!this.promptComparison?.formatPromptData) {
        this.snackBar.open('Format prompt data not loaded', 'Close', { duration: 3000 });
        return;
      }
    }

    this.showReport(
      isLeft ? this.promptComparison?.leftPromptData : this.promptComparison?.rightPromptData,
      this.promptComparison?.formatPromptData,
      patient
    );
  }

  showReport(
    selectedPrompt: Prompt | null,
    formatPrompt: Prompt | null,
    patient: Patient
  ) {
    // If it's current report (no prompt selected), show the current report
    if (!selectedPrompt) {
      this.dialog.open(ReportReviewDialogComponent, {
        width: '90%',
        height: '90%',
        data: {
          patient,
          report: patient.ReportReviewed || patient.Report,
          isEditable: false,
          title: 'Generated Report',
          onCopy: () => this.copyReport(patient)
        },
      });
      return;
    }

    // Validate selected prompt
    if (!selectedPrompt.name || !selectedPrompt.versionNumber ||
      !selectedPrompt.promptBlob || !selectedPrompt.lastUpdateDate) {
      this.snackBar.open('Invalid prompt data', 'Close', { duration: 3000 });
      return;
    }

    // Validate format prompt if provided
    if (formatPrompt && (!formatPrompt.name || !formatPrompt.versionNumber ||
      !formatPrompt.promptBlob || !formatPrompt.lastUpdateDate)) {
      this.snackBar.open('Invalid format prompt data', 'Close', { duration: 3000 });
      return;
    }

    this.reportService.generateReport(patient, selectedPrompt, formatPrompt)
      .then(report => {
        if (!report) {
          this.snackBar.open('No report generated', 'Close', { duration: 3000 });
          return;
        }

        this.dialog.open(ReportReviewDialogComponent, {
          width: '90%',
          height: '90%',
          data: {
            patient,
            report,
            isEditable: false,
            title: 'Generated Report',
            onCopy: () => this.copyReport(patient)
          },
        });
      })
      .catch(error => {
        console.error('Error generating report:', error);
        this.snackBar.open(
          error.message || 'Error generating report',
          'Close',
          { duration: 3000 }
        );
      });
  }

  async handleCompare(patient: Patient) {
    // Validate patient data
    if (!patient.ID || !patient.Name || !patient.StatusUpdatedDate) {
      this.snackBar.open('Missing patient data', 'Close', { duration: 3000 });
      return;
    }
    if (!patient.Blob) {
      this.snackBar.open('Patient has no conversation data', 'Close', { duration: 3000 });
      return;
    }

    // Validate right prompt (always required for comparison)
    if (!this.promptComparison?.rightPrompt) {
      this.snackBar.open('Please select right prompt', 'Close', { duration: 3000 });
      return;
    }
    if (!this.promptComparison?.rightVersion) {
      this.snackBar.open('Please select right prompt version', 'Close', { duration: 3000 });
      return;
    }
    if (!this.promptComparison?.rightPromptData) {
      this.snackBar.open('Right prompt data not loaded', 'Close', { duration: 3000 });
      return;
    }

    // Validate left prompt if not using Current Report
    if (this.promptComparison?.leftPrompt && this.promptComparison?.leftPrompt !== 'current') {
      if (!this.promptComparison?.leftVersion) {
        this.snackBar.open('Please select left prompt version', 'Close', { duration: 3000 });
        return;
      }
      if (!this.promptComparison?.leftPromptData) {
        this.snackBar.open('Left prompt data not loaded', 'Close', { duration: 3000 });
        return;
      }
    }

    // Validate format prompt if selected
    if (this.promptComparison?.formatPrompt) {
      if (!this.promptComparison?.formatVersion) {
        this.snackBar.open('Please select format prompt version', 'Close', { duration: 3000 });
        return;
      }
      if (!this.promptComparison?.formatPromptData) {
        this.snackBar.open('Format prompt data not loaded', 'Close', { duration: 3000 });
        return;
      }
    }

    await this.compareReports(
      this.promptComparison.leftPromptData,
      this.promptComparison.rightPromptData,
      this.promptComparison.formatPromptData,
      patient
    );
  }

  async compareReports(
    leftPrompt: Prompt | null,
    rightPrompt: Prompt | null,
    formatPrompt: Prompt | null,
    patient: Patient
  ) {
    let leftReport: string;
    let rightReport: string;
    let snackBarRef: any;

    try {
      // Step 1: Get left report
      snackBarRef = this.snackBar.open('Computing left report...', undefined, {
        duration: undefined // Keep open until dismissed
      });

      if (!leftPrompt) {
        leftReport = patient.ReportReviewed || patient.Report;
        if (!leftReport) {
          throw new Error('No existing report found for comparison');
        }
      } else {
        leftReport = await this.reportService.generateReport(patient, leftPrompt, formatPrompt);
      }

      snackBarRef.dismiss();
      this.snackBar.open('Left report completed', undefined, { duration: 1500 });

      // Step 2: Get right report
      snackBarRef = this.snackBar.open('Computing right report...', undefined, {
        duration: undefined
      });

      rightReport = await this.reportService.generateReport(patient, rightPrompt, formatPrompt);

      snackBarRef.dismiss();
      this.snackBar.open('Right report completed', undefined, { duration: 1500 });

      // Step 3: Compare reports
      snackBarRef = this.snackBar.open('Computing comparison...', undefined, {
        duration: undefined
      });

      // Validate reports
      if (!leftReport || !rightReport) {
        throw new Error('Failed to generate one or both reports');
      }

      // Create the comparison view
      const comparisonHtml = await this.createComparisonView(leftReport, rightReport);

      snackBarRef.dismiss();
      this.snackBar.open('Comparison completed', undefined, { duration: 1500 });

      // Show the comparison dialog
      this.dialog.open(ReportDialogComponent, {
        width: '90%',
        height: '90%',
        data: {
          report: comparisonHtml,
          onCopy: () => this.copyReport(patient)
        },
      });

    } catch (error) {

      // Dismiss any existing snackbar
      if (snackBarRef) {
        snackBarRef.dismiss();
      }

      // Log and show error
      console.error('Error in report comparison:', error);
      this.snackBar.open(
        error instanceof Error ? error.message : 'Error generating comparison reports',
        'Close',
        { duration: 5000 }
      );
    }
  }

  private async createComparisonView(leftReport: string, rightReport: string): Promise<string> {
    try {
      // Call the report service to get the comparison
      return await this.reportService.compareReports(leftReport, rightReport);
    } catch (error) {
      console.error('Error generating comparison view:', error);
      // Fallback to simple side-by-side view if comparison fails
      return `
        <table style="width: 100%; border-collapse: collapse;">
          <tr>
            <td style="width: 50%; vertical-align: top; padding: 10px; border-right: 1px solid #ccc;">
              ${leftReport}
            </td>
            <td style="width: 50%; vertical-align: top; padding: 10px;">
              ${rightReport}
            </td>
          </tr>
        </table>
      `;
    }
  }


  handlePaste(event: ClipboardEvent): void {
    event.preventDefault();
    const pastedText = event.clipboardData?.getData('text');
    if (pastedText) {
      const parsedDate = this.parseFlexibleDate(pastedText);
      if (parsedDate) {
        this.newPatient.MeetingDate = parsedDate;
        this.meetingTime = this.datePipe.transform(parsedDate, 'HH:mm') || '';
        this.updateMeetingDateTime();
      } else {
        this.snackBar.open('Invalid date format. Please use DD/MM/YYYY HH:mm or a similar format.', 'Close', {
          duration: 3000,
        });
      }
    }
  }

  parseFlexibleDate(dateString: string): Date | null {
    const formats = [
      /^(\d{1,2})\/(\d{1,2})\/(\d{2,4})(?:\s+(\d{1,2})(?::(\d{2}))?)?$/,  // DD/MM/YYYY HH:mm or DD/MM/YY HH:mm
      /^(\d{1,2})\/(\d{1,2})\/(\d{2,4})$/,  // DD/MM/YYYY or DD/MM/YY
    ];

    for (const format of formats) {
      const match = dateString.match(format);
      if (match) {
        const [_, day, month, year, hours = '0', minutes = '0'] = match;
        const fullYear = year.length === 2 ? '20' + year : year;
        const date = new Date(
          parseInt(fullYear),
          parseInt(month) - 1,
          parseInt(day),
          parseInt(hours),
          parseInt(minutes)
        );
        if (!isNaN(date.getTime())) {
          return date;
        }
      }
    }

    return null;
  }

  selectPatient(patient: Patient) {
    this.selectedPatient = patient;
    this.openReportReviewDialog(patient);
  }

  openReportReviewDialog(patient: Patient) {
    const dialogRef = this.dialog.open(ReportReviewDialogComponent, {
      width: '90%',
      height: '90%',
      data: {
        patient: patient,
          report: patient.ReportReviewed || patient.Report,
          isEditable: true,
          title: 'Current Report'
      },

    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveReviewedReport(patient.ID, result.reportReviewed);
      }
    });
  }

  saveReviewedReport(patientId: string, reportReviewed: string) {
    if (!patientId) {
      this.snackBar.open('Error: Patient ID is missing', 'Close', { duration: 3000 });
      return;
    }

    this.patientService.updatePatientReport(patientId, reportReviewed).subscribe({
      next: (response) => {
        if (response && response.message) {
          this.snackBar.open(response.message, 'Close', { duration: 3000 });
        } else {
          this.snackBar.open('Report review saved successfully', 'Close', { duration: 3000 });
        }
        this.loadPatients(); // Refresh the patient list
      },
      error: (error) => {
        console.error('Error saving report review:', error);
        if (error.error && error.error.message) {
          this.snackBar.open(`Error: ${error.error.message}`, 'Close', { duration: 5000 });
        } else {
          this.snackBar.open('Failed to save report review', 'Close', { duration: 3000 });
        }
      }
    });
  }

  onDateChange(event: any): void {
    if (event.value) {
      this.updateMeetingDateTime();
    }
  }

  updateMeetingDateTime(): void {
    if (this.newPatient.MeetingDate && this.meetingTime) {
      const [hours, minutes] = this.meetingTime.split(':').map(Number);
      const newDate = new Date(this.newPatient.MeetingDate);
      newDate.setHours(hours, minutes, 0, 0);
      this.newPatient.MeetingDate = newDate;
    }
  }

  async sendReminderSMS(patient: Patient) {
    const settings = await this.clinicSettingsService.getClinicSettings().toPromise();
    if (!settings?.ReminderSMSTemplate || !settings?.SmsSender) {
      this.snackBar.open('Reminder SMS template or sender name is not set. Please check your settings.', 'Close', { duration: 5000 });
      return;
    }

    const lastUpdateDays = patient.StatusUpdatedDate
      ? Math.floor((new Date().getTime() - new Date(patient.StatusUpdatedDate).getTime()) / (1000 * 3600 * 24))
      : null;

    const confirmMessage = `Are you sure you want to send a reminder SMS to this patient? ${lastUpdateDays !== null ? `It has been ${lastUpdateDays} days since their last status update.` : ''}`;

    if (confirm(confirmMessage)) {
      let smsBody = settings.ReminderSMSTemplate
        .replace('FirstName', patient.Name)
        .replace('UserCode', patient.KeyInTopicX.substring(0, Math.min(15, patient.KeyInTopicX.length - 1)))
        .replace('TherapistName', patient.DoctorName || '');

      const utcToLocalPipe = new UtcToLocalPipe();
      const meetingDateString = patient.MeetingDate
        ? utcToLocalPipe.transform(patient.MeetingDate)
        : '';
      smsBody = smsBody.replace('MeetingDate', meetingDateString);

      this.patientService.sendSms(patient, smsBody, settings.SmsSender).subscribe({
        next: (result) => {
          if (result.success) {
            this.snackBar.open('Reminder SMS sent successfully', 'Close', { duration: 3000 });
          } else {
            this.snackBar.open(`Failed to send reminder SMS: ${result.message}`, 'Close', { duration: 5000 });
          }
          this.loadPatients();
        },
        error: (error) => {
          console.error('Error sending reminder SMS:', error);
          this.snackBar.open('An error occurred while sending reminder SMS', 'Close', { duration: 5000 });
          this.loadPatients();
        }
      });
    }
  }

  notifyAiselTeam(patient: Patient) {
    const defaultMessage = `Hi Aisel team,
I would like to inform you that there may be a problem with patient with ID: ${patient.KeyInTopicX}.
The last status of the patient is ${patient.Status} and it was updated on ${this.formatDate(patient.StatusUpdatedDate)}.
Please advise.`;

    const dialogRef = this.dialog.open(NotifyAiselDialogComponent, {
      width: '900px',
      data: { defaultMessage }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sendNotificationSms(result);
      }
    });
  }

  private sendNotificationSms(message: string) {
    this.patientService.sendSms({ Phone: '+4561311297' } as Patient, message, 'aiselhelp').subscribe({
      next: (result) => {
        if (result.success) {
          this.snackBar.open('Notification sent to Aisel team successfully', 'Close', { duration: 3000 });
        } else {
          this.snackBar.open(`Failed to send notification: ${result.message}`, 'Close', { duration: 5000 });
        }
      },
      error: (error) => {
        console.error('Error sending notification:', error);
        this.snackBar.open('An error occurred while sending notification', 'Close', { duration: 5000 });
      }
    });
  }

  private formatDate(date: Date | string | null): string {
    if (!date) return 'N/A';
    return new Date(date).toLocaleString();
  }

  copyAiselId(id: string): void {
    this.clipboard.copy(id);
    this.snackBar.open('Aisel ID copied to clipboard', 'Close', {
      duration: 3000,
    });
  }

  refreshStatus() {
    this.isRefreshing = true;
    this.patientService.refreshStatus().subscribe({
      next: () => {
        this.loadPatients();
        this.isRefreshing = false;
      },
      error: (err) => {
        console.error('Error refreshing status:', err);
        this.snackBar.open('Failed to refresh status. Please try again later.', 'Close', {
          duration: 3000,
        });
        this.isRefreshing = false;
      }
    });
  }

  padZero(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.sort.active = 'Status';
    this.sort.direction = 'asc';
    this.sort.sortChange.emit({
      active: this.sort.active,
      direction: this.sort.direction,
    });
  }

  loadPatients() {
    this.isLoading = true;
    const isDevShowAll = this.showCreatePrompts && this.showAll;
    this.snackBar.open("Loading patients...", 'Close', { duration: 3000 });

    this.patientService.getPatients(this.showArchived, isDevShowAll).subscribe({
      next: (patients) => {
        // Filter out duplicates for developer view
        if (this.showCreatePrompts) {
          // Group patients by ID (removing '_' prefix if it exists)
          const groupedPatients = patients.reduce((acc, patient) => {
            const normalizedId = patient.ID.startsWith('_') ? patient.ID.substring(1) : patient.ID;
            if (!acc[normalizedId]) {
              acc[normalizedId] = [];
            }
            acc[normalizedId].push(patient);
            return acc;
          }, {} as { [key: string]: Patient[] });

          // For each group, prefer the starred version if it exists
          const filteredPatients = Object.values(groupedPatients).map(group => {
            const starredPatient = group.find(p => p.ID.startsWith('_'));
            return starredPatient || group[0];
          });

          this.dataSource.data = filteredPatients;
        } else {
          this.dataSource.data = patients;
        }

        this.applyFilter();
        this.isLoading = false;
      },
      error: (err) => {
        console.error('Error fetching patients:', err);
        this.error = 'Failed to fetch patients. Please try again later.';
        this.isLoading = false;
      },
    });
  }

  loadDefaultTherapistName() {
    this.clinicSettingsService.getClinicSettings().subscribe({
      next: (settings) => {
        this.defaultTherapistName = settings.DefaultTherapistName || '';
        this.newPatient.DoctorName = this.defaultTherapistName;
      },
      error: (error) => {
        console.error('Error loading default therapist name:', error);
      }
    });
  }

  applyFilter() {
    this.dataSource.filterPredicate = (data: Patient, filter: string) => {
      const textFilter = this.filterValue.trim().toLowerCase();

      if (this.showCreatePrompts) {
        return textFilter === '' ||
          data.Name?.toLowerCase().includes(textFilter) ||
          data.Status?.toLowerCase().includes(textFilter);
      }

      const matchesText =
        textFilter === '' ||
        data.Name?.toLowerCase().includes(textFilter) ||
        data.Phone?.includes(textFilter) ||
        data.DoctorName?.toLowerCase().includes(textFilter) ||
        data.KeyInTopicX?.toLowerCase().includes(textFilter);

      const matchesStatus =
        this.selectedStatuses.length === 0 ||
        this.selectedStatuses.includes(data.Status);

      return matchesText && matchesStatus;
    };

    this.dataSource.filter = '' + Math.random();
  }

  selectAll() {
    this.selectedStatuses = [...this.statusOptions];
    this.allSelected = true;
    this.applyFilter();
  }

  selectNone() {
    this.selectedStatuses = [];
    this.allSelected = false;
    this.applyFilter();
  }

  onStatusFilterChange() {
    this.allSelected =
      this.selectedStatuses.length === this.statusOptions.length;
    this.applyFilter();
  }

  getStatusLabel() {
    if (this.selectedStatuses.length === this.statusOptions.length) {
      return 'All statuses';
    } else if (this.selectedStatuses.length === 0) {
      return 'No statuses selected';
    } else {
      return this.selectedStatuses.join(', ');
    }
  }

  addPatient(form: any) {
    if (!this.newPatient.Name || !this.newPatient.Phone || !this.newPatient.MeetingDate || !this.meetingTime) {
      this.snackBar.open('Please fill in the required fields.', 'Close', {
        duration: 3000,
      });
      return;
    }

    const phonePattern = /^(?:\+45)?\s?\d{8}$/;
    if (!phonePattern.test(this.newPatient.Phone)) {
      this.snackBar.open(
        'Please enter a valid Danish mobile phone number.',
        'Close',
        { duration: 3000 }
      );
      return;
    }

    if (!this.newPatient.DoctorName) {
      this.newPatient.DoctorName = this.defaultTherapistName;
    }

    if (this.newPatient.MeetingDate && this.meetingTime) {
      const [hours, minutes] = this.meetingTime.split(':').map(Number);
      const localDate = new Date(this.newPatient.MeetingDate);
      localDate.setHours(hours, minutes, 0, 0);
      // Convert local time to UTC
      this.newPatient.MeetingDate = new Date(localDate.getTime());
    }

    this.patientService.addPatient(this.newPatient).subscribe({
      next: (patient) => {
        this.snackBar.open('Patient added successfully', 'Close', {
          duration: 3000,
        });

        const currentDoctorName = this.newPatient.DoctorName;

        // Reset the form fields except DoctorName
        form.resetForm();

        // Immediately set the DoctorName field back to its current value
        setTimeout(() => {
          this.newPatient = { DoctorName: currentDoctorName };
          form.form.patchValue({ DoctorName: currentDoctorName });
        });

        this.loadPatients();
      },
      error: (err) => {
        if (err.status === 409) {
          const errorMessage = err.error?.message || 'Patient with phone number:' + this.newPatient.Phone + ' already exists';
          this.snackBar.open(errorMessage, 'Close', {
            duration: 3000,
          });
        } else {
          this.snackBar.open('Failed to add patient', 'Close', {
            duration: 3000,
          });
        }
      },
    });
  }

  async sendInviteSMS(patient: Patient) {
    // 1. Check if SMS template and sender name are not null or empty
    const settings = await this.clinicSettingsService.getClinicSettings().toPromise();
    if (!settings?.SmsTemplate || !settings?.SmsSender) {
      this.snackBar.open('SMS template or sender name is not set. Please check your settings.', 'Close', { duration: 5000 });
      return;
    }

    // 2. Check if SMS was already sent
    if (patient.Status !== 'Created') {
      const confirmResend = confirm('An SMS was already sent to this patient. Do you want to send it again?');
      if (!confirmResend) return;
    }

    // 3. Replace tokens in SMS template
    let smsBody = settings.SmsTemplate
      .replace('FirstName', patient.Name)
      .replace('UserCode', patient.KeyInTopicX.substring(0, Math.min(15, patient.KeyInTopicX.length - 1)))
      .replace('TherapistName', patient.DoctorName || '');

    // New code to handle MeetingDate token
    const utcToLocalPipe = new UtcToLocalPipe();
    const meetingDateString = patient.MeetingDate
      ? utcToLocalPipe.transform(patient.MeetingDate)
      : '';
    smsBody = smsBody.replace('MeetingDate', meetingDateString);

    // 4. Call the sendSms function with details
    this.patientService.sendSms(patient, smsBody, settings.SmsSender).subscribe({
      next: (result) => {
        if (result.success) {
          this.snackBar.open('SMS sent successfully', 'Close', { duration: 3000 });
        } else {
          this.snackBar.open(`Failed to send SMS: ${result.message}`, 'Close', { duration: 5000 });
        }
        this.loadPatients(); // Reload patients to reflect updated status
      },
      error: (error) => {
        console.error('Error sending SMS:', error);
        this.snackBar.open('An error occurred while sending SMS', 'Close', { duration: 5000 });
        this.loadPatients(); // Reload patients to reflect updated status
      }
    });
  }

  viewReport(patient: Patient) {
    this.dialog.open(ReportDialogComponent, {
      data: {
        report: patient.ReportReviewed || patient.Report,
        onCopy: () => this.copyReport(patient)
      },
    });
  }

  copyReport(patient: Patient) {
    this.clipboard.copy(patient.ReportReviewed || patient.Report || '');
    this.snackBar.open('Report copied to clipboard', 'Close', {
      duration: 3000,
    });

    // Update the patient status to "Report copied"
    this.patientService.updatePatientStatus(patient.ID, '8 Report copied').subscribe({
      next: () => {
        patient.Status = '8 Report copied';
        patient.StatusUpdatedDate = new Date();
        this.dataSource.data = [...this.dataSource.data]; // Trigger change detection
      },
      error: (err) => {
        console.error('Error updating report copied status:', err);
        this.snackBar.open('Failed to update report status', 'Close', {
          duration: 3000,
        });
      }
    });
  }

  archiveTogglePatient(patient: Patient) {
    const isArchived = !!patient.ArchivedDate;
    const confirmationMessage = isArchived
      ? 'Are you sure you want to unarchive patient with phone: ' + patient.Phone + '?'
      : 'Are you sure you want to archive patient with phone: ' + patient.Phone + '?';

    const confirmation = confirm(confirmationMessage);
    if (confirmation) {
      this.patientService.archivePatient(patient.ID, !isArchived).subscribe({
        next: () => {
          const successMessage = isArchived
            ? 'Patient unarchived successfully'
            : 'Patient archived successfully';
          this.snackBar.open(successMessage, 'Close', {
            duration: 3000,
          });
          this.loadPatients();
        },
        error: () => {
          const errorMessage = isArchived
            ? 'Failed to unarchive patient'
            : 'Failed to archive patient';
          this.snackBar.open(errorMessage, 'Close', {
            duration: 3000,
          });
        },
      });
    }
  }

  handleStarClick(event: Event, patient: Patient) {
    event.stopPropagation(); // Prevent row selection

    this.snackBar.open("Saving Patient for dev...", 'Close', { duration: 3000 });

    this.patientService.toggleStar(patient).subscribe({
      next: () => {
        const message = patient.ID.startsWith('_')
          ? 'Patient unstarred successfully'
          : 'Patient starred successfully';

        this.loadPatients(); // Reload the list

        this.snackBar.open(message, 'Close', { duration: 3000 });
      },
      error: (error) => {
        console.error('Error toggling star:', error);
        this.snackBar.open('Error updating patient star status', 'Close', { duration: 3000 });
      }
    });
  }

  deletePatient(patient: Patient) {
    const confirmation = confirm(
      'Are you sure you want to delete patient with phone number:' + patient.Phone + ' ?'
    );
    if (confirmation) {
      this.patientService.deletePatient(patient.ID).subscribe({
        next: (response) => {
          this.snackBar.open('Patient with phone:' + patient.Phone + ' deleted successfully', 'Close', {
            duration: 3000,
          });
          this.loadPatients();
        },
        error: (err) => {
          console.error('Error deleting patient:', err);
          this.snackBar.open('Failed to delete patient', 'Close', {
            duration: 3000,
          });
        },
      });
    }
  }

  getStatusClass(status: string): string {
    switch (status) {
      case '1 Created':
        return 'bg-blue-100 text-blue-800';
      case '2 SMS sent':
        return 'bg-green-100 text-green-800';
      case '2e SMS attempt failed':
        return 'bg-green-100 text-red-800';
      case '3 Sms opened':
        return 'bg-yellow-100 text-yellow-800';
      case '4 Conversation started':
        return 'bg-indigo-100 text-indigo-800';
      case '5 Conversation ended':
        return 'bg-purple-100 text-purple-800';
      case '6 Report in review':
        return 'bg-pink-100 text-pink-800';
      case '7 Report ready':
        return 'bg-teal-100 text-teal-800';
      case '8 Report copied':
        return 'bg-gray-100 text-gray-800';
      case '9 Archived':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  }

  logout() {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: location.href.indexOf("localhost") > -1 ? 'http://localhost:4200' : 'https://clinic.aisel.co'
    });
  }

  isFormExpanded: boolean = true;

  toggleForm() {
    this.isFormExpanded = !this.isFormExpanded;
  }

  navigateToSettings() {
    this.router.navigate(['/settings']);
  }

  openPromptEditor() {
    const dialogRef = this.dialog.open(PromptEditorComponent, {
      width: '90%',
      height: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
